


















import { Component, Prop, Vue } from 'vue-property-decorator';
import { ConservatorshipDetails } from '@/modules/conservatorship/types';

const ConservatorshipReportPlugin = () => import('@/modules/reportOfConservatorshipManagement/components/ConservatorshipReportPlugin.vue');

@Component({
  components: { ConservatorshipReportPlugin }
})
export default class ReportTab extends Vue {
  @Prop({ type: Object }) conservatorship?: ConservatorshipDetails;
}
